<template>
  <div class="homePage">
    <HomeHeader :companyOption='companyOption' @changeCompany="changeCompany"></HomeHeader>
    <div class="contentArea">
      <div class="leftLayout">
        <div class="noticeList">
          <p class="title">待办通知</p>
          <div class="cells" :style="'height:'+noticeHeight+'px;'">

            <div class="cell" v-for="(item,index) in messageList" :key="index" @click="messageJump(item)">
              <div class="left">
                <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
                <span>{{item.serviceName}}</span>
              </div>
              <span class="notice" v-if="item.msgValue">{{item.msgValue}}</span>
            </div>

          </div>
        </div>
      </div>
      <div class="rightLayout">
        <div class="topApp" ref="topApp">
          <p class="title">应用中心</p>
          <div class="appBox">
            <div class="oneApp" v-for="(item, index) in productsMenu" :key="index" @click="platformJump(item)">
              <img :src="item.iconUrl?item.iconUrl:defaultPng" alt="">
              <div>
                <p class="p1">{{ item.productName }}</p>
                <p class="p2">{{item.productDesc}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="dataArea">
          <el-tabs type="border-card" @tab-click="changeTab">
            <el-tab-pane label="业务员情况" :lazy="true"></el-tab-pane>
            <el-tab-pane label="市场效果分析" :lazy="true"></el-tab-pane>
            <el-tab-pane label="终端和产品活跃情况" :lazy="true"></el-tab-pane>
          </el-tabs>
          <div class="charts" v-show="currentTab == '业务员情况'">
            <div id="fourthChart" ref="fourthChart" :style="'width: 50%;flex:1;height: '+echartHeight+'px'"></div>
            <div id="fifthChart" ref="fifthChart" :style="'width: 50%;flex:1;height: '+echartHeight+'px'"></div>
          </div>
          <div class="charts" v-show="currentTab == '市场效果分析'">
            <div id="firstChart" ref="firstChart" :style="'width: 100%;flex:1;height: '+echartHeight+'px'"></div>
          </div>
          <div class="charts" v-show="currentTab == '终端和产品活跃情况'">
            <div id="secondChart" ref="secondChart" :style="'width: 50%;flex:1;height: '+echartHeight+'px'"></div>
            <div id="thirdChart" ref="thirdChart" :style="'width: 50%;flex:1;height: '+echartHeight+'px'"></div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import newEcharts from 'echarts';
  import HomeHeader from '@/components/homeHeader.vue';
  import toOther from './mixins/toOther';
  import 'echarts/map/js/china';
  export default {
    name: 'marketingDigitalPlatform',
    components: {
      HomeHeader
    },
    data() {
      return {
        defaultPng: require('../../assets/personalPage/icon3.png'),
        currentTenantGuid: null,
        currentStaffGuid: null,
        companyOption: [],
        organisationName: '--',
        positionName: '--',
        messageList: [],
        productsMenu: [],
        dimen: 'order', //order=按报货 flow=按流向
        isSaleMan: 1, //业务员和其他为1，省总为0
        echartHeight: 0,
        noticeHeight: 0,
        currentTab:'业务员情况'
      };
    },
    mixins: [toOther],
    created() {
      document.title = '个人中心';
      localStorage.removeItem('loginType');
      this.getTenenants();
    },
    mounted() {
       this.noticeHeight = window.innerHeight - 170
    },
    methods: {
          //获取当前用户公司信息
      getTenenants() {
        var userGuid = localStorage.userId;
        this.api.get('/ms-personnel-service/staff-home/tenants?userGuid=' + userGuid).then((res) => {
          if (res.data.code == '00000') {
            this.companyOption = res.data.data.records;
            this.currentTenantGuid = this.$route.query.currentTenantGuid;
            this.companyOption.forEach((item) => {
              if (item.tenantGuid == this.currentTenantGuid) {
                this.currentStaffGuid = item.staffGuid;
              }
            });
            this.getMessageList();
            this.getOrgAndProduct();

          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      //获取消息列表
      getMessageList() {
        var menuList = JSON.parse(localStorage.menuList);
        var tenantGuid = this.currentTenantGuid;
        var currentStaffGuid = this.currentStaffGuid;
        this.api.get('/ms-sales-perform-service/msg-group/get?menuGuid=' + menuList[0].guid + '&tenantGuid=' +
          tenantGuid + '&staffGuid=' + currentStaffGuid).then((res) => {
          if (res.data.code == '00000') {
            this.messageList = res.data.data;
          } else {
            this.$message.error(res.data.msg);
          }
        });
      },
      //切换公司
      changeCompany(guid) {
        this.getMenuList(guid);
      },
      getMenuList(tenantGuid) {
        let self = this;
        self.api
          .get('/ms-common-admin-platform-auth-service/menu/data/get-menu-by-currentuser?' + `appType=01&tenantGuid=` +
            tenantGuid + `&appSideIdentifier=QYD`)
          .then((res) => {
          if (res.data.code == '00000') {
            self.menuList = res.data.data;
            if (res.data.data[0].menuName == '主页') {
              this.getCurrentstaff(tenantGuid,res.data.data[0].path)
              // window.location = location.origin + res.data.data[0].path + "?username=" + self.$route.query
              //   .username +
              //   '&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid;
            }
          } else {
            self.$message.error(res.data.msg);
            self.loadingInstance.close();
          }
        });
      },
      getCurrentstaff(tenantGuid,path) {
        let self = this;
        self.api.get('/ms-personnel-service/staff/data/get-currentstaff?' + `tenantGuid=` + tenantGuid).then((res) => {
          if (res.data.code == '00000') {
            self.$store.commit('$_setUserId', res.data.data.userGuid);
            localStorage.setItem('userInfo', JSON.stringify(res.data.data));
            window.location.replace(location.origin + path + "?username=" + self.$route.query.username +'&password=' + self.$route.query.password + '&currentTenantGuid=' + tenantGuid);
          } else {
            self.$message.error(res.data.msg);
            self.loadingInstance.close();
          }
        });
      },
      //获取当前公司下的菜单
      getOrgAndProduct() {
        this.api
          .get(
            '/ms-personnel-service/staff-home/org-and-product?staffGuid=' + this.currentStaffGuid +
            '&tenantGuid=' + this.currentTenantGuid,
          )
          .then((res) => {
            if (res.data.code == '00000') {
              this.organisationName = res.data.data.organisationName;
              this.positionName = res.data.data.positionName;
              this.productsMenu = res.data.data.products;
              this.productsMenu.forEach(item=>{
                if(item.icon){
                  let obj = JSON.parse(item.icon)
                  this.$set(item,'iconUrl',obj.path)
                }
              })
              //渲染应用产品后，渲染图表
              this.$nextTick(() => {
                let height = this.$refs.topApp.offsetHeight
                this.echartHeight = window.innerHeight - height - 190
                this.getStaffData(); 
                this.getActiveUser()
              })
              
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      //切换图标tab
      changeTab(e) {
        this.currentTab = e.label
        if (e.label == '市场效果分析') {
          this.getLiHuoData();
        } else if (e.label == '终端和产品活跃情况') {
          this.$nextTick(()=>{
            this.getPunchData();
            this.getProductData()
          })
        }else{
          this.getStaffData()
          this.getActiveUser()
        }
      },

      //市场效果分析
      getLiHuoData() {
        this.api
          .get(
            '/ms-bizdata-statistics-service/personnel-report/market-effect?tenantGuid=' +
            this.currentTenantGuid +
            '&staffGuid=' +
            this.currentStaffGuid,
          )
          .then((res) => {
            if (res.data.code == '00000') {
              this.initChart(res.data.data);
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      //终端活跃情况
      getPunchData() {
        this.api.get('/ms-bizdata-statistics-service/personnel-report/customer-brisk?tenantGuid=' +this.currentTenantGuid +
            '&staffGuid=' +this.currentStaffGuid,
          )
          .then((res) => {
            if (res.data.code == '00000') {
              // console.log('终端活跃情况',res)
              if(res.data.data){
              this.initPunchChart(res.data.data);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      //产品活跃情况
      getProductData() {
        this.api.get('/ms-bizdata-statistics-service/personnel-report/customer-brisk-goods?tenantGuid=' +
            this.currentTenantGuid +'&staffGuid=' +this.currentStaffGuid,
          )
          .then((res) => {
            if (res.data.code == '00000') {
              console.log('产品活跃情况',res)
              if(res.data.data){
              this.initThirdChart(res.data.data);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      //获取业务员分布情况
      getStaffData(){
        this.api.get('/ms-bizdata-statistics-service/personnel-report/salesman-distribution?tenantGuid=' +
            this.currentTenantGuid +'&staffGuid=' +this.currentStaffGuid,
          )
          .then((res) => {
            if (res.data.code == '00000') {
              // console.log('获取业务员分布情况',res)
              if(res.data.data){
              this.initFourthChart(res.data.data);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      // 月用户活跃趋势
      getActiveUser(data){
        let code = ''
        let name = '全国'
        if (data) {
          code = data.code
          name = data.sourceName ? data.sourceName : data.name
        }
        this.api.get('/ms-bizdata-statistics-service/personnel-report/salesman-brisk-trend?tenantGuid=' +
            this.currentTenantGuid+'&staffGuid=' +this.currentStaffGuid +'&provinceCode='+code,
          )
          .then((res) => {
            if (res.data.code == '00000') {
              if(res.data.data){
                this.initFifthChart(res.data.data,name);
              }
            } else {
              this.$message.error(res.data.msg);
            }
          });
      },
      //市场效果分析
      initChart(data) {
        let xData = data.lateralAxis;
        let dataArr1 = [];
        let dataArr2 = [];
        let dataArr3 = []; 
        data.datas.forEach((item) => {
          if (item.name == '实际流向额') {
            dataArr1 = item.values;
          }
          if (item.name == '实际报货额') {
            dataArr2 = item.values;
          }
          if(item.name == '差异率'){
            dataArr3 = item.values;
          }
        });
        let myChart = this.$echarts.init(document.getElementById('firstChart'));
        myChart.clear();
        myChart.resize();
        let option = {
          title: {
            text: '市场效果分析',
            left: '10px',
            textStyle: {
              color: '#4D4D4D',
              fontWeight: '500',
              fontSize: 16,
            },
            top: 0,
            letf: 0,
          },
          grid: {
            containLabel: true,
            top: '60px',
            left: '30px',
            right: '30px',
            bottom: '10px',
          },
          tooltip: {
            trigger: 'axis',
            extraCssText: 'text-align:left;',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function(params) {
              return (
                params[0].name +
                '<br>' +
                params[0].seriesName +
                '：' +
                params[0].data +
                '万元<br/>' +
                params[1].seriesName +
                '：' +
                params[1].data +
                '万元<br/>' +
                params[2].seriesName +
                '：' +
                params[2].data +
                '%'
              );
            },
          },
          toolbox: {
            feature: {
              dataView: {
                show: false,
                readOnly: false,
              },
              magicType: {
                show: false,
                type: ['line', 'bar'],
              },
              restore: {
                show: false,
              },
              saveAsImage: {
                show: false,
              },
            },
          },
          legend: {
            data: ['实际流向额', '实际报货额', '差异率'],
            right: '20px',
            top: '1%',
          },
          xAxis: [{
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          }, ],
          yAxis: [{
              type: 'value',
              name: '单位：万元',
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                },
              },
            },
            {
              type: 'value',
              name: '单位：%',
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: false,
                lineStyle: {
                  type: 'dashed',
                },
              },
            },
          ],
          series: [{
              name: '实际流向额',
              barMaxWidth: '25px',
              type: 'bar',
              data: dataArr1,
              itemStyle: {
                normal: {
                  color: '#669CDB',
                },
                borderRadius: [6, 6, 0, 0],
              },
            },
            {
              name: '实际报货额',
              barMaxWidth: '25px',
              type: 'bar',
              data: dataArr2,
              itemStyle: {
                normal: {
                  color: '#A3D9D3',
                },
                borderRadius: [4, 4, 0, 0],
              },
            },
            {
              name: '差异率',
              type: 'line',
              data: dataArr3,
              yAxisIndex: 1,
              smooth: true,
              itemStyle: {
                normal: {
                  color: '#5055C9',
                },
              },
              showSymbol: false,
              areaStyle: {
                opacity: 1,
                color: new newEcharts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(80, 85, 201, 0.2)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                ]),
              },
              emphasis: {
                focus: 'series',
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener('resize', function() {
          myChart.resize();
        });
      },
      //终端活跃情况
      initPunchChart(data) {
        let xData = ['本月'];
        let dataArr1 = [data.briskCount];
        let dataArr2 = [data.newAddCount];
        let dataArr3 = [data.noOrderCount];
        let myChart = this.$echarts.init(document.getElementById('secondChart'));
        myChart.clear();
        let option = {
          title: {
            text: '终端活跃情况',
            left: '10px',
            textStyle: {
              color: '#4D4D4D',
              fontWeight: '500',
              fontSize: 16,
            },
            top: 0,
            letf: 0,
          },
          grid: {
            containLabel: true,
            top: '60px',
            left: '30px',
            right: '30px',
            bottom: '10px',
          },
          tooltip: {
            trigger: 'axis',
            extraCssText: 'text-align:left;',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function(params) {
              return (
                params[0].name +
                '<br>' +
                params[0].seriesName +
                '：' +
                params[0].data +
                '家<br/>' +
                params[1].seriesName +
                '：' +
                params[1].data +
                '家<br/>'+
                params[2].seriesName +
                '：' +
                params[2].data +
                '家'
              );
            },
          },
          toolbox: {
            feature: {
              dataView: {
                show: false,
                readOnly: false,
              },
              magicType: {
                show: false,
                type: ['line', 'bar'],
              },
              restore: {
                show: false,
              },
              saveAsImage: {
                show: false,
              },
            },
          },
          legend: {
            data: ['活跃', '新增','近三月沉寂'],
            right: '20px',
            top: '1%',
          },
          xAxis: [{
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          }, ],
          yAxis: [{
            type: 'value',
            name: '单位：家',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          }],
          series: [{
              name: '活跃',
              barMaxWidth: '25px',
              type: 'bar',
              data: dataArr1,
              itemStyle: {
                normal: {
                  color: '#669CDB',
                },
                borderRadius: [6, 6, 0, 0],
              },
            },
            {
              name: '新增',
              barMaxWidth: '25px',
              type: 'bar',
              data: dataArr2,
              itemStyle: {
                normal: {
                  color: '#A3D9D3',
                },
                borderRadius: [4, 4, 0, 0],
              },
            },
            {
              name: '近三月沉寂',
              barMaxWidth: '25px',
              type: 'bar',
              data: dataArr3,
              itemStyle: {
                normal: {
                  color: '#ebb762',
                },
                borderRadius: [4, 4, 0, 0],
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener('resize', function() {
          myChart.resize();
        });
      },
      //产品活跃情况
      initThirdChart(data) {
        let xData = data.lateralAxis;
        let dataArr1 = [];
        let dataArr2 = [];
        let dataArr3 = [];
        data.datas.forEach(item=>{
          if(item.name =='活跃终端'){
            dataArr1 = item.values
          }
          if(item.name =='新增'){
            dataArr2 = item.values
          }
          if(item.name =='报货额占比'){
            dataArr3 = item.values
          }
        })
        let myChart = this.$echarts.init(document.getElementById('thirdChart'));
        myChart.clear();
        let option = {
          title: {
            text: '产品活跃情况',
            left: '10px',
            textStyle: {
              color: '#4D4D4D',
              fontWeight: '500',
              fontSize: 16,
            },
            top: 0,
            letf: 0,
          },
          grid: {
            containLabel: true,
            top: '60px',
            left: '30px',
            right: '30px',
            bottom: '10px',
          },
          tooltip: {
            trigger: 'axis',
            extraCssText: 'text-align:left;',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function(params) {
              return (
                params[0].name +
                '<br>' +
                params[0].seriesName +
                '：' +
                params[0].data +
                '家<br/>' +
                params[1].seriesName +
                '：' +
                params[1].data +
                '家<br/>'+
                params[2].seriesName +
                '：' +
                params[2].data +
                '%'
              );
            },
          },
          toolbox: {
            feature: {
              dataView: {
                show: false,
                readOnly: false,
              },
              magicType: {
                show: false,
                type: ['line', 'bar'],
              },
              restore: {
                show: false,
              },
              saveAsImage: {
                show: false,
              },
            },
          },
          legend: {
            data: ['活跃终端', '新增终端','报货额占比'],
            right: '20px',
            top: '1%',
          },
          xAxis: [{
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          }, ],
          yAxis: [{
            type: 'value',
            name: '单位：家',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: true,
              lineStyle: {
                type: 'dashed',
              },
            },
          },{
            type: 'value',
            name: '单位：%',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          }],
          series: [{
              name: '活跃终端',
              barMaxWidth: '25px',
              type: 'bar',
              data: dataArr1,
              itemStyle: {
                normal: {
                  color: '#669CDB',
                },
                borderRadius: [6, 6, 0, 0],
              },
            },
            {
              name: '新增终端',
              barMaxWidth: '25px',
              type: 'bar',
              data: dataArr2,
              itemStyle: {
                normal: {
                  color: '#A3D9D3',
                },
                borderRadius: [4, 4, 0, 0],
              },
            },
            {
              name: '报货额占比',
              type: 'line',
              yAxisIndex:1,
              data: dataArr3,
              smooth: true,
              itemStyle: {
                normal: {
                  color: '#dfb978',
                },
              },
              showSymbol: false,
              areaStyle: {
                opacity: 1,
                color: new newEcharts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(223, 185, 120, 0.2)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                ]),
              },
              emphasis: {
                focus: 'series',
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener('resize', function() {
          myChart.resize();
        });
      },
      //业务员分布情况
      initFourthChart(data){        
      let geoChart = this.$echarts.init(document.getElementById('fourthChart'));
      geoChart.clear();
      geoChart.off('click');
      let resultData = [];
      data.forEach(item=>{
        let str = item.name
        if(item.name.indexOf('省')>0){
          str = item.name.replace('省', '')
        }else if(item.name.indexOf('市')>0){
          str = item.name.replace('市', '')
        }else if(item.name.indexOf('回族自治区')>0){
          str = item.name.replace('回族自治区', '')
        }else if(str.indexOf('维吾尔自治区')>0){
          str = item.name.replace('维吾尔自治区', '')
        }else if(str.indexOf('壮族自治区')>0){
          str = item.name.replace('壮族自治区', '')
        }else if(item.name.indexOf('自治区')>0){
          str = item.name.replace('自治区', '')
        }else if(item.name.indexOf('特别行政区')>0){
          str = item.name.replace('特别行政区', '')
        }
        let obj = {
          name: str,
          sourceName:item.name,
          code:item.code,
          value:item.staffSum
        }
        resultData.push(obj)
      })
      data.sort((a,b)=>{
        return b.staffSum - a.staffSum
      })
      let maxNum = data[0].staffSum
      let option = {
        title: {
          text: '业务员分布情况',
          left: '10px',
          textStyle: {
            color: '#4D4D4D',
            fontWeight: '500',
            fontSize: 16,
          },
          top: 0,
        },
        grid: {
            containLabel: true,
            top: '60px',
        },
        tooltip: {
          show: true,
          formatter: function (params) {
            if (params.value) {
              return params.name + ' : ' + params.value+' 人';
            } else {
              return params.name;
            }
          },
        },
        visualMap: {
        min: 0,
        max: maxNum,
        itemWidth:14,
        itemHeight:100,
        left:30,
        text: ['高', '低'],
        realtime: false,
        calculable: true,
        inRange: {
          color: ['#f1f2f2', '#4fa1a4']
        }
      },
        geo: {
          map: 'china',
          show: true,
          roam: false,
          label: {
            show: false,
              emphasis: {
                show: false,
              },
          },
            emphasis: {
              label: {
                show: false,
              },
              itemStyle: {
                areaColor: '#3DBFAB',
                borderColor: '#e5e5e5',
                borderWidth: 1,
              },
            },
            itemStyle: {
              areaColor: '#f4f4f7',
              borderColor: '#e5e5e5',
              borderWidth: 1,
            },
        },
        series: [
          {
            type: 'map',
            map: 'china',
            aspectScale: 0.75,
            geoIndex: 0,
            data:resultData,
          },
        ],
      };
      geoChart.setOption(option);
      window.addEventListener('resize', function () {
        geoChart.resize();
      });

      var that = this;
      geoChart.on('click', function (params) {
        console.log(params); //此处写点击事件内容
        if (params.componentSubType == "map") {
          that.getActiveUser(params.data);
        }
      });
      },
      // 月或与用户趋势(近6个月)
      initFifthChart(data,title) {
        let xData = data.lateralAxis;
        let dataArr1 = [];
        data.datas.forEach((item) => {
          if (item.name == '人数') {
            dataArr1 = item.values;
          }
        });
        let myChart = this.$echarts.init(document.getElementById('fifthChart'));
        myChart.clear();
        myChart.resize();
        let option = {
          title: {
            text: '业务员月活跃趋势（'+title+'）',
            left: '10px',
            textStyle: {
              color: '#4D4D4D',
              fontWeight: '500',
              fontSize: 16,
            },
            top: 0,
            letf: 0,
          },
          grid: {
            containLabel: true,
            top: '60px',
            left: '30px',
            right: '30px',
            bottom: '10px',
          },
          tooltip: {
            trigger: 'axis',
            extraCssText: 'text-align:left;',
            axisPointer: {
              type: 'shadow',
              crossStyle: {
                color: '#999',
              },
            },
            formatter: function(params) {
              return (
                params[0].name +
                '<br>' +
                params[0].seriesName +
                '：' +
                params[0].data +
                '人'
              );
            },
          },
          toolbox: {
            feature: {
              dataView: {
                show: false,
                readOnly: false,
              },
              magicType: {
                show: false,
                type: ['line', 'bar'],
              },
              restore: {
                show: false,
              },
              saveAsImage: {
                show: false,
              },
            },
          },
          legend: {
            data: ['人数'],
            right: '20px',
            top: '1%',
          },
          xAxis: [{
            type: 'category',
            data: xData,
            axisPointer: {
              type: 'shadow',
            },
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            splitLine: {
              show: false,
              lineStyle: {
                type: 'dashed',
              },
            },
          }],
          yAxis: [{
              type: 'value',
              name: '单位：人',
              axisLine: {
                show: false,
              },
              axisTick: {
                show: false,
              },
              splitLine: {
                show: true,
                lineStyle: {
                  type: 'dashed',
                },
              },
            }
          ],
          series: [
            {
              name: '人数',
              type: 'line',
              data: dataArr1,
              smooth: true,
              itemStyle: {
                normal: {
                  color: '#5055C9',
                },
              },
              showSymbol: false,
              areaStyle: {
                opacity: 1,
                color: new newEcharts.graphic.LinearGradient(0, 0, 0, 1, [{
                    offset: 0,
                    color: 'rgba(80, 85, 201, 0.2)',
                  },
                  {
                    offset: 0.5,
                    color: 'rgba(255, 255, 255, 0.1)',
                  },
                ]),
              },
              emphasis: {
                focus: 'series',
              },
            },
          ],
        };
        myChart.setOption(option);
        window.addEventListener('resize', function() {
          myChart.resize();
        });
      },

    },
  };
</script>

<style scoped>
.topApp p,
.noticeList p{
  padding: 0;
  margin: 0;
}
  .homePage {
    background: #F6F7FA;
    min-height: 100vh;
    width: 100%;
    padding: 0.8rem 1rem 1rem 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .logoArea {
    display: flex;
    align-items: flex-end;
    padding-bottom: 1.5rem;
  }

  .logoArea img {
    height: 2.125rem;
    margin-right: 1.875rem;
  }

  .logoArea span {
    font-size: 1.25rem;
    color: #323233;
  }

  .contentArea {
    display: flex;
    flex: 1;
    height: 100%;
    width: 100%;
  }

  /* 左侧 */
  .leftLayout {
    width: 22%;
    padding: 1.25rem 1.5rem;
    box-sizing: border-box;
    background: #fff;
  }

  /* .companyName {
    width: 70%;
    margin: 0 auto;
    font-size: 1.2rem;
    color: #4D4D4D;
    font-family: 'PingFang SC';
    margin-bottom: 0.375rem;
    font-weight: 600;
  } */
    .userInfo /deep/ .el-input__inner {
    border: none;
    padding: 0;
    font-size: 18px;
    color: #4D4D4D;
    font-family: 'PingFang SC';
    margin-bottom: 6px;
    font-weight: 600;
    /* width: 82%; */
  }

  .userInfo /deep/ .el-select-dropdown__item.selected {
    color: #4fa1a4;
  }

  .userInfo /deep/ .el-select .el-input .el-select__caret {
    height: auto;
    font-size: 1.2rem;
    color: #4D4D4D;
  }
  .userInfo {
    text-align: center;
    /* height: 40%; */
    padding-bottom: 14%;
  }

  .userInfo .userIcon {
    margin-top: 2rem;
    margin-bottom: 1.0625rem;
  }

  .userInfo .userIcon img {
    width: 6rem;
    height: 6rem;
    border: 0.75rem solid rgb(223, 225, 230, 0.2);
    border-radius: 50%;
    box-sizing: border-box;
  }

  .userInfo .position {
    color: #4D4D4D;
    font-size: 1rem;
    line-height: 1.75rem;
  }

  .noticeList .title {
    margin-bottom: 1rem;
    padding-bottom: 16px;
    font-size: 1rem;
    color: #323233;
    border-bottom: 1px solid #DFE1E6;
    text-align: left;
    font-weight: 600;
  }

  .noticeList .cells {
    overflow-y: auto;
  }

  .noticeList .cell {
    padding: 0.75rem;
    box-sizing: border-box;
    height: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 0.875rem;
    color: #323233;
    border-bottom: 1px solid rgba(116, 116, 116, 0.06);
    cursor: pointer;
  }

  .noticeList .cell:hover {
    transition: all 0.5s;
    color: #4fa1a4;
  }

  .noticeList .cell .left {
    display: flex;
    align-items: center;
  }

  .noticeList .cell img {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    margin-right: 0.5rem;
  }

  .noticeList .cell .notice {
    width: 1.25rem;
    height: 1.25rem;
    line-height: 1.25rem;
    text-align: center;
    background: #FF4831;
    border-radius: 50%;
    font-size: 0.625rem;
    color: #fff;
  }

  /* 右侧 */
  .rightLayout {
    flex: 1;
    padding-left: 1rem;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
  }

  .topApp {
    background: #fff;
    padding: 1.25rem 1.5rem 0.25rem 1.5rem;
  }

  .topApp .title {
    font-size: 1.125rem;
    color: #323233;
    border-bottom: 1px solid #DFE1E6;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    text-align: left;
    font-weight: 600;
  }

  .topApp .appBox {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .topApp .oneApp {
    width: 23%;
    padding: 0.75rem 1rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-align: left;
    background: #F6F7FA;
    margin-right: 2%;
    margin-bottom: 1rem;
    cursor: pointer;
    box-sizing: border-box;
  }

  .topApp .oneApp:hover {
    transition: all 0.5s;
    box-shadow: 0px 0 0.625rem 3px rgba(0, 0, 0, 0.1);
  }

  .topApp .oneApp img {
    width: 3rem;
    height: 3rem;
    margin-right: 1rem;
  }

  .topApp .oneApp .p1 {
    font-size: 1rem;
    margin-bottom: 4px;
  }

  .topApp .oneApp .p2 {
    font-size: 0.75rem;
  }

  .dataArea {
    margin-top: 1rem;
    flex: 1;
    width: 100%;
    background: #fff;
  }

  .dataArea .charts {
    padding: 0 1.5rem;
    display: flex;
    width: 100%;
    box-sizing: border-box;
  }

  .dataArea /deep/ .el-tabs__item {
    width: 14.5rem;
    color: #323233;
    font-size: 1rem;
    height: 3rem;
    line-height: 3rem;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item:not(.is-disabled):hover {
    color: #323233;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    color: #323233;
    font-weight: 600;
    box-shadow: 2px 0 0.5rem 1px rgba(0, 0, 0, 0.1);
  }

  .dataArea /deep/ .el-tabs--border-card {
    border: none;
    box-shadow: 0 0 0 0;
  }

  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header,
  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item,
  .dataArea /deep/ .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
    border: none;
  }
</style>
